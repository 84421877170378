<template>
  <v-card :loading="loading">

    <template slot="progress">
      <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
    </template>

    <v-card-title>Notizen</v-card-title>

    <v-card-text v-if="!loading">

      <v-row no-gutters class="mt-4">
        <v-col cols="10">

          <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="safeNote()">
            <v-text-field v-model="note" label="Notiz hinzufügen" :rules="[rules.required, rules.min]" outlined :clearable="true"></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="2">

          <v-btn v-if="isFormValid" class="my-2 mx-2" fab dark small color="green" @click="safeNote" :loading="fetchinData">
            <v-icon dark>mdi-send</v-icon>
          </v-btn>

          <v-btn v-else class="my-2 mx-2" fab small color="green" disabled>
            <v-icon dark>mdi-send</v-icon>
          </v-btn>

        </v-col>
      </v-row>


      <DataTable v-if="data && data.length > 0"
      @tableAction="emittedAction" 
      v-bind:items="data.slice().reverse()" 
      v-bind:headers="headers" 
      :preloader="fetchinData" 
      :searchPosBottom = "true"/>

      <div v-else>
        <p>Noch keine Notizen vorhanden. Zeit etwas zu schreiben!</p>
      </div>
      
    </v-card-text>
    
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest"
import DataTable from '@/components/ui/DataTable.vue'
export default {

  name: 'NotesCard',

  components: {
    DataTable
  },

  props: ['data','loading','pitchId'],

  data () {

    return {

      isFormValid: false,
      fetchinData: false,
      note: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 3 || 'Min 3 characters',
      },
      trash: true,
      search: '',
      headers: [
        { text: 'Datum', value: 'created'},
        { text: 'Von', value: 'adminName'},
        { text: 'Notiz', value: 'note', width: "66.66%", padding: "10px"},
      ]

    }
  },

  created: function () {
    //
  },

  methods: {

    emittedAction() {
      //
    },

    async safeNote() {

     if (this.isFormValid) {

        const formData = new FormData();
        formData.append('note', this.note);

        this.fetchinData= true;
        let response = await this.postRequest('pitches/'+this.pitchId, '', formData); // await data from mixin
        this.fetchinData= false;

        if (response.status === 200) {

          /* CALL SNACKBAR */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = 'Die Notiz wurde hinzugefügt.';
          this.$root.snackbarGlobal.snackbarColor = 'primary'
          this.$root.snackbarGlobal.snackbarTimeout = 5000

          this.note = '';
          this.$refs.form.resetValidation();

          this.data.push(response.data.data); // mutate data

        } else {

          /* CALL SNACKBAR */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = 'Ein Fehler ist aufgetreten!';
          this.$root.snackbarGlobal.snackbarColor = 'red'
          this.$root.snackbarGlobal.snackbarTimeout = 5000

        }


      }

    },
    
    transformToTrueFalseState(value) {

      if (!value) {
        
        return false;

      } else {

        if (value === 'nein' || value === 'no' || value == null || value == 0 ) {
          return false;
        } else {
          return true;
        }

      }

    }

  },

  computed: {
    //
  },

  mixins: [apiRequest],

}
</script>