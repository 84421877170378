<template>
  <v-card :loading="loading">
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title>Gebietskarte</v-card-title>

    <v-card-text>
      <GmapMap
        :center="center"
        :zoom="11"
        :options="options"
        style="width: 100%; height: 400px"
      >
        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        ></gmap-info-window>

        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          :icon="m.icon"
          :infowindow="m.position.infowindow"
          @click="toggleInfoWindow(m, i)"
        />
      </GmapMap>

      <table class="default-table">
        <tbody>
          <tr>
            <td><img :src="orangeMarker" alt="pin " style="width:20px;" /></td>
            <td>Aktueller Pitch</td>
          </tr>
          <tr>
            <td><img :src="purpleMarker" alt="pin " style="width:20px;" /></td>
            <td>Bestandskunde</td>
          </tr>
          <tr>
            <td><img :src="blueMarker" alt="pin " style="width:20px;" /></td>
            <td>Jahresmarketing-Kunde</td>
          </tr>
          <tr>
            <td><img :src="yellowMarker" alt="pin " style="width:20px;" /></td>
            <td>Anderer aktiver Pitch</td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
const greenMarker = require("@/assets/map-pin-green.png");
const greenMarkerJmp = require("@/assets/map-pin-green-jmp.png");
const orangeMarker = require("@/assets/map-pin-orange.png");
const yellowMarker = require("@/assets/map-pin-yellow.png");
const blueMarker = require("@/assets/map-pin-blue.png");
const purpleMarker = require("@/assets/map-pin-purple.png");

/* https://github.com/xkjyeah/vue-google-maps/tree/8d6bbac96b0797cf1e5b9537d58920c23ba75bd2 */
export default {
  name: "MapCard",

  components: {
    //
  },

  props: ["data", "loading"],

  data() {
    return {
      orangeMarker: orangeMarker,
      greenMarker: greenMarker,
      greenMarkerJmp: greenMarkerJmp,
      yellowMarker: yellowMarker,
      blueMarker: blueMarker,
      purpleMarker: purpleMarker,
      /* Demos: https://github.com/xkjyeah/vue-google-maps/tree/8d6bbac96b0797cf1e5b9537d58920c23ba75bd2/examples */

      center: {
        lat: parseFloat(this.data.companyLatitude),
        lng: parseFloat(this.data.companyLongitude),
      },

      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      i: 0,

      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },

      markers: this.buildMarkers(),

      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 10],
        type: "poly",
      },

      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
    };
  },

  created: function() {
    //
  },

  methods: {
    buildMarkers() {
      const companiesNearby = this.data.companiesNearby;
      const potentialsNearby = this.data.potentialsNearby;

      let result = [];

      let homeElement = {
        position: {
          lat: parseFloat(this.data.companyLatitude),
          lng: parseFloat(this.data.companyLongitude),
        },
        label: this.data.id,
        icon: orangeMarker,
        info: {
          name: this.data.companyName,
          adress: this.data.companyStreet,
          location: this.data.companyZipCode + " " + this.data.companyTown,
          infoText: "",
          distance: 0,
        },
      };

      result.push(homeElement);

      companiesNearby.forEach((item) => {
        let pin = purpleMarker;

        if (item.isJmp === "1") {
          pin = blueMarker;
        }

        let element = {
          position: {
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          },
          label: item.id,
          icon: pin,
          info: {
            name: item.name,
            adress: item.street,
            location: item.zipCode + " " + item.town,
            distance: parseInt(item.distance),
            infoText: "",
          },
        };

        result.push(element);
      });

      potentialsNearby.forEach((item) => {
        let element = {
          position: {
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          },
          label: item.id,
          icon: yellowMarker,
          info: {
            name: item.name,
            adress: item.street,
            location: item.zipCode + " " + item.town,
            distance: parseInt(item.distance),
            infoText: "",
          },
        };

        result.push(element);
      });

      return result;
    },

    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = `
      <h4>${marker.info.name}</h4>
      ${marker.info.adress}<br/>
      ${marker.info.location}<br/>
      Entfernung: ca. ${marker.info.distance} Kilometer (Luftlinie)`;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
};
</script>
