<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{ edit: true }"
          :search="search"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          :loading="preloader2"
          @click="createNewDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Einen neuen Pitch anlegen</span>
    </v-tooltip>

    <AreYouSureDialog
      title="Soll der Pitch storniert werden?"
      text="Wenn du den Pitch tatsächlich löschen möchtest, klicke auf 'Ja'. Ansonsten klicke auf 'Nein'."
      :dialog="dialog"
      @dialogEmit="dialogEmit"
    />

    <v-dialog v-model="createNewDialog" persistent max-width="812px">
      <v-card>
        <v-card-title
          ><span class="text-h5">Einen neuen Pitch anlegen</span></v-card-title
        >
        <v-card-text>
          <DataTable
            :key="tableKey"
            @tableAction="potentialClientsEmittedAction"
            :items="potentialClientsItems"
            :headers="potentialClientsHeaders"
            :itemActions="null"
            :isSelectable="potentialClientsIsSelectable"
            :selectedItems="potentialClientsSelectedItems"
            :disableDoubleClick="true"
            :preloader="potentialClientsPreloader"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="
              createNewDialog = false;
              tableKey += 1;
            "
            >Abbrechen</v-btn
          >

          <v-btn
            v-if="potentialClientsSelectedItems.length > 0"
            color="green"
            dark
            @click="createPitches"
            >Pitch anlegen</v-btn
          >
          <v-btn v-else color="green" disabled>Pitch anlegen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="detailDialog"
      max-width="1200px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Pitch-Detail</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <Pitch :id="idToEdit" @updated="mutate($event)" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
import Pitch from "@/views/sales/pitches/Pitch.vue";
export default {
  name: "index",

  components: {
    PageHeader,
    DataTable,
    AreYouSureDialog,
    Pitch,
  },

  data() {
    return {
      tableKey: 0,
      pageTitle: "Pitches",
      pageDescription: `<p>Hier kannst du alle aktiven Pitches einsehen und verwalten.</p>`,
      title: "",
      preloader: false,
      preloader2: true,
      items: [],
      headers: [],
      search: null,
      dialog: false,
      createNewDialog: false,
      detailDialog: false,
      idToEdit: null,
      componentKey: 0,
      /* second data-table (potential clients in dialog) */
      potentialClientsPreloader: false,
      potentialClientsHeaders: [],
      potentialClientsItems: [],
      potentialClientsEdit: false,
      potentialClientsTrash: false,
      potentialClientsPreview: false,
      potentialClientsCopy: false,
      potentialClientsIsSelectable: false,
      potentialClientsSearch: "",
      potentialClientsSelectedItems: [],
    };
  },

  created: function () {
    this.getData();
    this.getPotentialsData();
  },

  methods: {
    mutate(obj) {
      // obj contains status and id
      // find the item in items and update the status
      let item = this.items.find((item) => item.id === obj.id);
      item.status = obj.status;
      this.tableKey += 1;
    },

    async createPitches() {
      this.tableKey += 1; // update key to rerender table component (to clear selection ... ruff, i know)
      this.createNewDialog = false; // close dialog

      //console.log("CREATE PITCHES");
      //console.log(this.potentialClientsSelectedItems);

      let idsString = ""; // commaseparated list to pass via POST request

      // build commaseparated list
      this.potentialClientsSelectedItems.forEach((item) => {
        if (idsString === "") {
          idsString = item.id;
        } else {
          idsString += "," + item.id;
        }
      });

      let formData = new FormData();
      formData.append("potentialIds", idsString);

      this.preloader = true;
      let response = await this.postRequest("pitches", "", formData);
      this.preloader = false;

      response.data.data.forEach((item) => {
        this.items.unshift(item); // mutate items
      });
    },

    async cancelSale() {
      /* da ich die ID und den INDEX vorher den currentXX Vars zugewiesen habe, kann ich diese nun verwenden */
      /* let response = await this.deleteRequest('products/' + this.currentItemId); // await data from mixin

      if (response.status === 200) {
        this.items.splice(this.currentItemIndex, 1); // remove from array
      }  */
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.cancelSale();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Pitch wurde erfolgreich storniert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Pitch wurde nicht storniert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async createNew() {
      /* create new entry */
      /*  let response = await this.postRequest('products');

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem);

      this.$router.push({ name: 'tech.shop.products.:id', params: { id: newItem.id } }) */
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */

        this.currentItemId = value.itemId;
        this.currentItemIndex = value.index;
        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        this.componentKey += 1;
        this.idToEdit = value.itemId;
        this.detailDialog = true;

        /* this.$router.push({
          name: "sales.pitches.:id",
          params: { id: value.itemId },
        }); */
      }
    },

    potentialClientsEmittedAction(value) {
      if (value.action === "itemsSelected") {
        this.potentialClientsSelectedItems =
          value.items; /* update with selection from table */
      }
    },

    async getData() {
      this.preloader = true;
      let pitchesResponse = await this.getRequest(
        "pitches?include=potentials&filter[isClosed]=0"
      );
      //let pitchesResponse = await this.getRequest("pitches");
      this.preloader = false;

      if (pitchesResponse.status === 200) {
        this.headers = [
          { text: "Status", value: "pitchState" },
          { text: "Initiator", value: "userFirstname" },
          { text: "Name", value: "name" },
          { text: "Postleitzahl", value: "zipCode" },
          { text: "Stadt", value: "town" },
          { text: "Actions", value: "actions", sortable: false },
        ];
        console.log(pitchesResponse.data);
        this.items = pitchesResponse.data.data.reverse();
      }
    },

    async getPotentialsData() {
      this.preloader2 = true;
      let potentialClientsResponse = await this.getRequest(
        "potentialClients?filter[pitches]=notClosed"
      ); // GET List of potential clients, that not have an active pitch
      this.preloader2 = false;

      if (potentialClientsResponse.status === 200) {
        /* Second data-table (potential clients in dialog) */

        this.potentialClientsHeaders = [
          { text: "Name", value: "name" },
          { text: "Land", value: "countryCode" },
          { text: "Postleitzahl", value: "zipCode" },
          { text: "Stadt", value: "town" },
        ];

        this.potentialClientsItems = potentialClientsResponse.data.data;
        this.potentialClientsEdit = false;
        this.potentialClientsTrash = false;
        this.potentialClientsCopy = false;
        this.potentialClientsPreview = false;
        this.potentialClientsIsSelectable = true;
        this.potentialClientsSearch = "";
      }
    },
  },

  mixins: [apiRequest],
};
</script>
