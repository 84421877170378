<template>
  <v-card :loading="loading">
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title>Übersicht</v-card-title>

    <v-card-text v-if="!loading && Object.keys(data).length > 0">
      <table class="default-table">
        <tbody>
          <tr>
            <td>Firmenname</td>
            <td>{{ data.companyName }}</td>
          </tr>
          <tr>
            <td>Adresse</td>
            <td>{{ data.companyStreet }}</td>
          </tr>
          <tr>
            <td>Ort</td>
            <td>{{ data.companyzipCode }} {{ data.companyTown }}</td>
          </tr>
          <tr>
            <td>Ansprechpartner</td>
            <td>
              {{ data.companySalutation }} {{ data.companyRepresentatives }}
            </td>
          </tr>
          <tr>
            <td>E-Mail</td>
            <td>
              <a :href="'mailto:' + data.companyEmail">{{
                data.companyEmail
              }}</a>
            </td>
          </tr>
          <tr>
            <td>Telefon</td>
            <td>{{ data.companyPhone }}</td>
          </tr>
          <tr>
            <td colspan="2" class="primary darken-2 white--text">Meta</td>
          </tr>
          <tr>
            <td>Pitch Startdatum</td>
            <td>{{ data.created }}</td>
          </tr>
          <tr>
            <td>Initiator</td>
            <td>{{ data.userFirstname }} {{ data.userLastname }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <Patcher
                :itemValue="data.status"
                itemName="status"
                :route="patchRoute"
                inputElement="select"
                inputType=""
                :selectOptions="selectOptions"
                itemValueKey="id"
                itemTextKey="name"
                :previewValue="data.status"
                label=""
                @emit="updatedItem"
              ></Patcher>
            </td>
          </tr>
        </tbody>
      </table>

      <v-btn
        tile
        dark
        color="red"
        @click="lockDialog = true"
        v-if="data.status === 'isLost'"
      >
        <v-icon left>mdi-lock</v-icon>
        Pitch schließen
      </v-btn>

      <v-btn
        tile
        dark
        color="green"
        @click="transferToClientDialog = true"
        v-else-if="data.status === 'isWon'"
      >
        <v-icon left>mdi-lock</v-icon>
        Nach "Kunde" umwandeln
      </v-btn>

      <span class="caption" v-else>
        Ein Pitch kann erst dann geschlossen werden, wenn entweder Status
        "Verloren" oder Status "Gewonnen" vorliegt.
      </span>
    </v-card-text>

    <AreYouSureDialog
      title="Pitch schließen?"
      text="Möchtest du diesen Pitch tatsächlich schließen? Dies kann nicht rückgängig gemacht werden."
      :dialog="lockDialog"
      @dialogEmit="dialogEmit"
    />

    <AreYouSureDialog
      title="Nach Kunde umwandeln und Pitch schließen?"
      text="Möchtest du diese Firma als Kunden anlegen und diesen Pitch tatsächlich schließen? Dies kann nicht rückgängig gemacht werden. Achtung: Der Pitch wird als Erfolgreich geschlossen, der Kunde wird jedoch noch nicht als Kunde umgewandelt. Dies muss noch manuell erfolgen."
      :dialog="transferToClientDialog"
      @dialogEmit="dialogEmitClient"
    />
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
//import DataPatcher from "@/components/ui/DataPatcher.vue";
import Patcher from "@/components/ui/DataPatcher.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
export default {
  name: "OverviewCard",

  components: {
    Patcher,
    AreYouSureDialog,
  },

  props: ["data", "loading"],

  data() {
    return {
      lockDialog: false,
      transferToClientDialog: false,
      patchRoute: "pitches/" + this.data.id,
      status: this.data.status,

      selectOptions: [
        {
          name: "Offen",
          id: "isOpen",
        },
        {
          name: "Kontaktiert",
          id: "isContacted",
        },
        {
          name: "Interessiert",
          id: "isInterested",
        },
        {
          name: "Angebot gesendet",
          id: "offerSent",
        },
        {
          name: "Terminiert",
          id: "isScheduled",
        },
        {
          name: "Verloren",
          id: "isLost",
        },
        {
          name: "Gewonnen",
          id: "isWon",
        },
      ],
    };
  },

  methods: {
    updatedItem(value) {
      const status = value.response.data.data.status;
      const id = value.response.data.data.id;
      this.data.status = status;
      const result = {
        status: status,
        id: id,
      };
      this.$emit("updated", result);
    },

    async dialogEmitClient(value) {
      /* Schließen des Pitches und umwandlung zum Kunden */

      if (value) {
        const params = new URLSearchParams();
        params.append("isClosed", 1);

        this.preloader = true;
        let response = await this.patchRequest(this.patchRoute, params); // await data from mixin -> Formdata: title, prio,
        this.preloader = false;

        if (response) {
          this.lockDialog = false;

          this.$router.push({ name: "sales.pitches" });

          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Datensatz wurde geschlossen!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        this.lockDialog = false;
      }
    },

    async dialogEmit(value) {
      if (value) {
        const params = new URLSearchParams();
        params.append("isClosed", 1);

        this.preloader = true;
        let response = await this.patchRequest(this.patchRoute, params); // await data from mixin -> Formdata: title, prio,
        this.preloader = false;

        if (response) {
          this.lockDialog = false;

          this.$router.push({ name: "sales.pitches" });

          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Datensatz wurde geschlossen!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        this.lockDialog = false;
      }
    },

    emit(value) {
      if (value.action === "itemPatched") {
        if (value.name === "status") {
          this.data.status = value.value; // mutate
        }
      }

      /* mutate notes in data array 
      after a status patch, a notes gets automated written */
      this.data.notes = value.response.data.data.notes;
    },
  },

  mixins: [apiRequest],
};
</script>
